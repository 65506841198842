.main-heading{
  h1{
    color: var(--white-color);
    letter-spacing: .025em;
    font-size: 36px;
    font-weight: 400;
    @media(max-width: 991px){
      font-size: 27px;
    }
  }
}