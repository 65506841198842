:root {
    --primary-color: #5e17eb;
    --white-color: #f8f7f7e6;
    --softGrey-color: #FCFBFF;
    --grey-color: #212529;
    --black-color:#141516;
    --darkBlack-color: #0f0f0f;
}

body {
    font-family: 'Poppins';
    color: var(--white-color);
    line-height: 1.5;
    background-color: var(--darkBlack-color);
}