#footer {
  background-color: var(--black-color);

  .footer-wrapper {
    padding: 80px 0;
    @media(max-width: 991px) {
      padding: 30px 0;
      p {
        font-size: 14px;
      }
    }
  }
}