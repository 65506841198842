.main-button {
  position: relative;
  top: 0;
  left: 0;
  width: 250px;
  height: 50px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    bottom: -5px;
    width: 30px;
    height: 10px;
    background: var(--primary-color);
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
    background: var(--primary-color);
    box-shadow: 0 0 5px #5e17eb, 0 0 15px #5e17eb, 0 0 30px #5e17eb, 0 0 60px #5e17eb;

  }

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    top: -5px;
    width: 30px;
    height: 10px;
    background: var(--primary-color);
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
    background: var(--primary-color);
    box-shadow: 0 0 5px #5e17eb, 0 0 15px #5e17eb, 0 0 30px #5e17eb, 0 0 60px #5e17eb;

  }

  &:hover {
    &:before {
      bottom: 0;
      height: 50%;
      width: 80%;
      border-radius: 30px;
    }

    &:after {
      top: 0;
      height: 50%;
      width: 80%;
      border-radius: 30px;
    }

    a {
      &:before {
        transform: skewX(45deg) translate(200px);
      }
    }
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 250px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 30px;
    padding: 10px;
    letter-spacing: 1px;
    text-decoration: none;
    overflow: hidden;
    color: #fff;
    font-weight: 400;
    z-index: 1;
    transition: 0.5s;
    backdrop-filter: blur(15px);

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
      transform: skewX(45deg) translate(0);
      transition: 0.5s;
      filter: blur(0px);
    }
  }
}

.project-button {
  display: flex;
  align-items: center;
  gap: 10px;

  .project-btn {
    background: var(--primary-color);
    color: white;
    transition: all 450ms ease-in;
    border: 1px solid transparent;

    &:hover {
      background-color: transparent;
      border: 1px solid var(--primary-color);
    }
  }
}