#about-us {
  .about-us__wrapper {
    padding: 180px 0 120px;
    @media(max-width: 991px){
     padding:  90px 0 80px;
    }

    .about-us__text {
      padding-bottom: 50px;
      max-width: 980px;
      width: 100%;
      margin: auto;
      @media(max-width: 991px){
        padding-bottom: 10px;
      }
      p, h2 {
        font-size: 29px;
        letter-spacing: .025em;
        @media(max-width: 991px){
          font-size: 23px;
          margin: 0 10px;
        }

        span {
          color: var(--primary-color);
          font-weight: 500;
        }
      }
    }

    .about-us__stack {
      margin-top: 90px;
      @media(max-width: 991px){
        margin-top: 65px;

      }

      .about-stack__list {
        margin-top: 35px;
        background-color: var(--black-color);
        padding: 10px;
        filter: grayscale(60%) contrast(1);
        transition: all .2s ease-in-out;
        border-radius: 3px;
        width: 100%;
        max-width: 180px;
        @media(max-width: 390px){
          max-width: 160px;

        }

        &:hover {
          transform: translateY(-5px) scale(1.1);
          filter: grayscale(0);
        }
        p{
          @media(max-width: 991px){
            font-size: 14px;
          }
        }

        img {
          width: 55px;
        }
      }
    }
  }
}

#projects {
  background-color: var(--black-color);
  .projects-wrapper {
    padding: 100px 0 120px;
    @media(max-width: 991px){
      padding: 80px 0 120px;
    }
    .projects-list {
      .project-item {
        background-color: var(--darkBlack-color);

        .project-content {
          .project-content__info{
            @media(max-width: 991px){
              margin-top: 15px;
            }
            .project-heading{
              .project-title{
                font-size: 1.5rem;
                line-height: 2rem;
                margin-bottom: 15px;
              }
            }
            .ul-tech{
              li{
                color: #586565;
                font-size: 18px;
                line-height: 28px;
                font-weight: 500;
                &:not(:last-child){
                  border-right: 1px solid rgb(229, 231, 235);
                  margin-right: 5px;
                  padding-right: 10px;
                }
              }
            }
            .project-description{
              p{
                font-weight: 400;
                margin: 10px 0;
              }
            }
          }
          .project-image {
            img {
              width: 100%;
              max-width: 500px;
              margin: auto;
            }
          }
        }
      }
    }
  }
}

#contact {
  .contact-wrapper {
    padding: 50px 0;
    .contact-description {
      padding-bottom: 50px;
      max-width: 980px;
      width: 100%;
      margin: auto;
      p{
        font-size: 19px;
        letter-spacing: 0.025em;
        line-height: 1.9;
        @media(max-width: 991px){
          font-size: 16px;
          margin: 0 10px;
        }
        a{
          color: var(--primary-color);
          font-weight: 500;
          display: block;
        }
        .social-icon{
          max-width: 35px;
          width: 100%;
          margin: 15px auto auto;
          filter: grayscale(60%) contrast(1);
          transition: all 0.2s ease-in-out;
          &:hover{
            transform: translateY(-5px) scale(1.1);
            filter: grayscale(0);
          }
        }
        img{
          display: block;
        }
      }
    }
  }
}