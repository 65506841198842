#header {
  background-color: var(--black-color);

  .header-wrapper {
    padding: 15px 0;
    position: relative;
    @media(max-width: 991px) {
      padding: 10px;
    }

    .header-wrapper__logo {
      margin-bottom: -70px;
      @media(max-width: 991px){
        margin-bottom: -40px;
      }

      img {
        max-width: 120px;
        @media(max-width: 991px){
          max-width: 90px
        }
      }
    }

    .header-wrapper__links {

      @media(max-width: 991px) {
        opacity: 0;
        visibility: hidden;
        &.active {
          opacity: 1;
          visibility: visible;
          a{
            color: var(--black-color);
          }
        }
        transition: 0.3s ease;
        top: 100%;
        right: 0;
        position: absolute;
        z-index: 2;
        background-color: var(--white-color);

      }

      ul {
        @media(max-width: 991px) {
          padding: 0 22px;
        }

        li {
          @media(max-width: 991px) {
            list-style: none;
            padding: 12px 0;
          }

          a {
            font-weight: 500;
          }
        }
      }
    }

    #menu-bar {
      width: 35px;
      cursor: pointer;
      position: relative;
      z-index: 3;
      .bar {
        height: 5px;
        width: 100%;
        background-color: var(--white-color);
        display: block;
        border-radius: 5px;
        transition: 0.3s ease;
      }

      #bar1 {
        transform: translateY(-4px);
      }

      #bar3 {
        transform: translateY(4px);
      }
    }

  }
}

.menu {
  &.active {
    #bar1 {
      transform: translateY(4px) rotateZ(-45deg) !important;
    }
    #bar2 {
      opacity: 0;
    }
    #bar3 {
      transform: translateY(-6px) rotateZ(45deg) !important;
    }
  }
}